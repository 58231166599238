import React, { useState } from "react";
import { table, base } from "../config/AirtableConfig";
import "./AirtableForm.scss";
import logo from '../assets/images/Antartika_logo.png'
import logo_small from '../assets/images/logo192.png'

const AirtableForm = () => {
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(amount);
      };
    
    // Set default values: 
    const defaultTasaAimani = 4.99; 
    const defaultTasaMarsella = 3.5; 

    // states for form to submit
    const [ejecutivo, setEjecutivo] = useState("");
    const [fechaOperacion, setFechaOperacion] = useState("");
    const [fechaVencimiento, setFechaVencimiento] = useState("");
    const [operador, setOperador] = useState("");
    const [montoOperacion, setMontoOperacion] = useState("");
    const [tasaAimani, setTasaAimani] = useState(defaultTasaAimani);
    const [tasaMarsella, setTasaMarsella] = useState(defaultTasaMarsella);
    const [tasaRose, setTasaRose] = useState("");

    // states for record fetching
    const [record, setRecord] = useState(null);
  
    // function to fetch records from airtable by record ID. 
    const fetchRecord = async (recordId) => {
        try {
          const fetchedRecord = await table.find(recordId);
          setRecord(fetchedRecord.fields);
          alert(`Cheque ingresado, ganancia de ${formatCurrency(record["aimani_utility"])} Pesos`);
        } catch (error) {
          console.error("Error fetching record:", error);
        }
      };

    // function to handle the form submit
    const handleSubmit = async () => {
      try {
        const response = await table.create([
          {
            fields: {
              "Ejecutivo/a": ejecutivo,
              "operation_date": fechaOperacion,
              "expiry_date": fechaVencimiento,
              // "Tasa Rose": tasaRose,
              "Tasa Aimani": parseFloat(tasaAimani), //using default value in case no value was added
              "Tasa Marsella": parseFloat(tasaMarsella),
              // "Operador": operador,
              "Monto Operacion": parseFloat(montoOperacion),
            },
          },
        ]);
        // Fetch the record after submitting the form
        fetchRecord(response[0].id);
      } catch (error) {
        console.error("Error creating record:", error);
      }
    };

  return (
    <div className="factoring-container" >
        <div className="topbar">
          <img src={logo_small} alt="antartika logo" />
          <h2>Calculadora de Factoring</h2>
        </div>
        <label className="agente">
          <span>
            Agente:
          </span>
            <select className="singleSelect"
                value={ejecutivo}
                onChange={(e) => setEjecutivo(e.target.value)}
            >
                <option value="">Seleccionar Agente</option>
                <option value="feesquivel2@gmail.com">Fidel Esquivel </option>
                <option value="maiteestay@gmail.com">Maite Estay</option>
            </select>
        </label>
        <div className="fechas">
          <label className="date">
              Dia de Operacion:
              <input
                  type="date"
                  value={fechaOperacion}
                  onChange={(e) => setFechaOperacion(e.target.value)}
                  />
          </label>
          <label className="date">
              Fecha Vencimiento:
              <input
                  type="date"
                  value={fechaVencimiento}
                  onChange={(e) => setFechaVencimiento(e.target.value)}
                  />
          </label>
        </div>
        <label className="tasas">
            Tasas a comparar:
            <table>
                <thead>
                  <tr>
                    <th>Tasa Aimani</th>
                    <th>Tasa Marsella</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="number"
                        value={tasaAimani}
                        onChange={(e) => setTasaAimani(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={tasaMarsella}
                        onChange={(e) => setTasaMarsella(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
            </table>
        </label>
        <label className="monto">
            Monto Operacion (Pesos):
            <input
                type="number"
                value={montoOperacion}
                onChange={(e) => setMontoOperacion(e.target.value)}
            />
        </label>

        <button onClick={handleSubmit}>Enviar</button>
        <br />
        {record ? (<>
            <div className="summary">
              <h3>Detalles de la operacion</h3>
              <p>Plazo: {record["Plazo"]} dias</p>
              <p>Ganancia: {formatCurrency(record["aimani_utility"])} Pesos</p>
              <span>Tasa:</span>
              <table>
                <thead>
                  <tr>
                    <th>Aimani</th>
                    <th>Rose</th>
                    <th>Marsella</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{record["Tasa Aimani"]}</td>
                    <td>{record["Tasa Rose"]}</td>
                    <td>{record["Tasa Marsella"]}</td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <span>Interés a pagar:</span>
              <table>
                <thead>
                  <tr>
                    <th>Aimani</th>
                    <th>Rose</th>
                    <th>Marsella</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatCurrency(record["aimani_interest"])}</td>
                    <td>{formatCurrency(record["payment_rose"])}</td>
                    <td>{formatCurrency(record["payment_marsella"])}</td>
                  </tr>
                </tbody>
              </table>

              <br />
              <p>Monto Operacion: {formatCurrency(record["Monto Operacion"])}</p>
              <p>Mejor precio: {record["best_deal"]}</p>


            </div>
        </>
        ): <div className="filler"><img src={logo} alt="" className="fullLogo"/></div>}
    </div>
  );
};

export default AirtableForm;


