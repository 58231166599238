import React, { useState, useEffect } from "react";
import AirtableForm from "./components/AirtableForm";
import { firebaseConfig } from "./config/Firebase";

function App() {

  
  return (
    <div>
      <AirtableForm/>
    </div>
  );
};

export default App;
